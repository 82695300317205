import React from 'react';

const TextLeft = ({id}) => (
  <div id={id} class="textLeft">
    <div class="textLeftInner">
      <div class="leftHalf">
      <h3>Languages I know:</h3>
        <ul>
          <li>CSS</li>
          <li>Javascript</li>
          <li>Python</li>
          <li>Java</li>
          <li>And More!</li>
        </ul>
      </div>
      <div class="rightHalf"></div>
    </div>
  </div>
)

export default TextLeft;