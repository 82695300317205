import React from 'react';

const TextRight = ({id}) => (
  <div id={id} class="textRight">
    <div class="textRightInner">
      <div class="leftHalf"></div>
      <div class="rightHalf">
        <h3>Security is a concern...</h3>
          <p>In today’s world Web server security is a vital part of any organization that has a physical or virtual Web server connected to the Internet. A security breach can be very costly to any organization in terms of finances and reputation. For this reason, I incorporate layered defence in my coding methodology. My education has been focused on computer security, both offensive and defensive, in order to mitigate these threats.</p>
          <p>No website can be perfectly secure, but I will strive to insure it is as secure as possible.</p>
      </div>
    </div>
  </div>
)

export default TextRight;