import React from 'react';
// import Button from '@material-ui/core/Button';

const ContactForm = ({ id }) => (
  <div id={id}>
    <div id="contactFormInner">
      <form name="contactMe" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/">
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" />
          </label>
        </p>
        <p>
          <label>Your Name: <input type="text" name="name" /></label>
        </p>
        <p>
          <label>Your Email: <input type="email" name="email" /></label>
        </p>
        <p>
          <label>Message: <textarea name="message"></textarea></label>
        </p>
        <p style={{ textAlign: 'center' }}>
          {/* <Button variant="contained" color="primary" size="large" type="submit">Send</Button> */}
          <button type="submit">
            Send
          </button>
        </p>
      </form>

      <div class="formLogo"></div>

    </div>
  </div>
)

export default ContactForm;