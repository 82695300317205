import React from 'react';

const JeopardyProj = ({ id }) => (
    <div id={id} class="textLeft">
        <div class="textLeftInner">
            <div class="leftHalf">
                <h3>Jeopardy</h3>
                <p>Live Jeopardy project. Playable between multiple computers. Technology used in this project:</p>
                <ul>
                    <li>React (Javascript)</li>
                    <li>Flask (Python)</li>
                    <li>PostgreSQL for database</li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://socket.io/">Socket.io</a></li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://auth0.com/">Auth0</a></li>
                </ul>
                <a target="_blank" rel="noopener noreferrer" href="http://Jeopardy.MattFisher.ca/" className="button">Click here to see!</a>
            </div>
            <div class="rightHalf"></div>
        </div>
    </div >
)

export default JeopardyProj;