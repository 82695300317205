import React from "react"

const FloatingTextBox = ({id, title, paragraph}) => (
  <div id={id}>
    {/* <h1>{title}</h1> */}
    {paragraph}
    <div class="icons">
      <a target="_blank" rel="noopener noreferrer" class="linkedIn iconImage" href="https://www.linkedin.com/in/matthewfisheryyc/">LinkedIn</a>
      <a target="_blank" rel="noopener noreferrer" class="gitHub iconImage" href="https://github.com/Matt-Fisherrr">GitHub</a>
    </div>
  </div>
)

export default FloatingTextBox;