import React from "react"
import '../style/main.css'

import Layout from "../components/layout"
import SEO from "../components/seo"

import FloatingTextBox from "../components/floatingtextbox"
import EmptySpace from "../components/emptySpace";
import TextLeft from "../components/textleft";
import TextRight from "../components/textright";
import ContactForm from "../components/contactform";
import JeopardyProj from "../components/jeopardyproj";

const descParagraph = <p>Welcome to my website. I am a full stack developer, with a main interest in backend development and solving complicated logic problems, who is also very knowledgeable in a wide variety of IT concepts such as security, PC/server assembly and repair. Currently I reside in Calgary Canada; however, I am willing to relocate as opportunities present themselves. My hobbies include playing video games and golfing. <br /> Thank you for visiting my site.</p>

const index = () => (
  <Layout>
    <SEO title="index" />
    <EmptySpace id="topPicture"/>
    <FloatingTextBox id="descBox" title="Matt Fisher" paragraph={descParagraph} />
    <TextLeft id="firstImage" />
    <TextRight id="secondImage" />
    <div className="centeredLine"><hr/></div>
    <h5 className="ProjTitle">Projects</h5>
    <JeopardyProj id="JeopardyProj" />
    <ContactForm id="contactForm" />
  </Layout>
)

export default index;
